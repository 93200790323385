import React from 'react'

import {Editor, EditorState, ContentState, convertFromRaw, convertToRaw} from 'draft-js'
import draftToHtml from 'draftjs-to-html';

import Audio from './Audio'
import Image from './Image'
import ElementBox from './ElementBox'
import Video from '../../modules/elements/components/Video'
import ImageZoom from './ImageZoom'

import CmsHelper from '../../../../../helpers/Cms'

function fpDraftToHtml(rawContentState) {
  if(rawContentState.blocks) {
    rawContentState.blocks.map((block, i) => {
      if(block.type === 'paragraph') {
        rawContentState.blocks[i].type = 'unstyled'
      }
    })
  }
  return draftToHtml(rawContentState);
}

function reverseArr(input) {
    var ret = new Array;
    for(var i = input.length-1; i >= 0; i--) {
        ret.push(input[i]);
    }
    return ret;
}

class Element extends React.Component {
  render() {
    var content
    var settings = this.props.settings
    switch(this.props.type) {
      case 'accordion':
        var sections = settings.sections.map((section) => {
          const rawContentState = section.settings.text
          const markup = fpDraftToHtml(
            rawContentState
          );
          return(
            <div key={section.id} className="section">
              <div className="section-head" style={{cursor: 'pointer'}}>
                <div className="headline h2">
                  <div className="element-text">
                    {section.settings.headline}
                  </div>
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.858" height="8.479" viewBox="0 0 16.858 8.479"><path d="M4332.7,2592.82l-7.432-8.091,7.432-8.091" transform="translate(-2576.301 4333.072) rotate(-90)" strokeWidth="1"></path></svg>
                  </div>
                </div>
              </div>
              <div className="section-body">
                <div className="element-text">
                  <div dangerouslySetInnerHTML={{ __html: markup }} />
                </div>
              </div>
            </div>
          )
        })
        content = (
          <div className="element-container element-container-accordion">
            <div className="accordion">
              <div className="sections">
                {
                  sections
                }
              </div>
            </div>
          </div>
        )
        break;
      case 'tabs':
        var tabsButtons = []
        var tabsBoxes = []
        if(settings !== undefined && settings.tabs !== undefined && settings.tabs.length > 0) {
          tabsButtons = settings.tabs.map((tab, index) => {
            return(
              <div
                key={index}
                className={'tabs-button' + (index === 0 ? ' active': '')}
              >
                <div className="element-text">{tab.settings.headline}</div>
              </div>
            )
          })
          tabsBoxes = settings.tabs.map((tab, index) => {
            const rawContentStateText = tab.settings.text
            const markupText = fpDraftToHtml(
              rawContentStateText
            );
            return(
              <div
                key={index}
                className={'tabs-box' + (index === 0 ? ' active': '')}
              >
                <div className="headline h2">
                  <div className="element-text">{tab.settings.headline}</div>
                </div>
                <div className="text">
                  <div dangerouslySetInnerHTML={{ __html: markupText }} />
                </div>
              </div>
            )
          })
        }
        content = (
          <div className="element-container element-container-tabs">
            <div className="tabs">
              <div className="tabs-buttons">
                {tabsButtons}
              </div>
              <div className="tabs-boxes">
                {tabsBoxes}
              </div>
            </div>
          </div>
        )
        break;
      case 'button':
        let buttontext = '';
        let buttonUrl = '';
        if(settings !== undefined && settings.description !== undefined) {
          buttontext = settings.description;
        }
        if(settings !== undefined && settings.url !== undefined) {
          buttonUrl = settings.url;
        }
        if(buttontext == '') {
          buttontext = 'Button';
        }
        content = (
          <div className="element-container element-container-button">
            <div className="button-element">
              <a class="button" href={buttonUrl} target="_blank">{buttontext}</a>
            </div>
          </div>
        )
        break;
      case 'flashcards':
        var cards = []
        if(settings !== undefined && settings.cards !== undefined && settings.cards.length > 0) {
          cards = settings.cards.map((card, index) => {
            const rawContentStateTextFront = card.settings.text_front
            const markupTextFront = fpDraftToHtml(
              rawContentStateTextFront
            );
              const rawContentStateTextBack = card.settings.text_back
              const markupTextBack = fpDraftToHtml(
                rawContentStateTextBack
              );
            return(
              <div
                key={index}
                className='card'
              >
                <div className="card-inner">
                  <div className="card-front">
                    <div className="headline h2">
                      <div className="element-text">{card.settings.headline_front}</div>
                    </div>
                    <div className="text">
                      <div dangerouslySetInnerHTML={{ __html: markupTextFront }} />
                    </div>
                  </div>
                  <div className="card-back">
                    <div className="headline h2">
                      <div className="element-text">{card.settings.headline_back}</div>
                    </div>
                    <div className="text">
                      <div dangerouslySetInnerHTML={{ __html: markupTextBack }} />
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
        content = (
          <div className="element-container element-container-flashcards">
            <div className="flashcards">
              {cards}
            </div>
          </div>
        )


        var tabsButtons = []
        var tabsBoxes = []
        if(settings !== undefined && settings.tabs !== undefined && settings.tabs.length > 0) {
          tabsButtons = settings.tabs.map((tab, index) => {
            return(
              <div
                key={index}
                className={'tabs-button' + (index === 0 ? ' active': '')}
              >
                <div className="element-text">{tab.settings.headline}</div>
              </div>
            )
          })
          tabsBoxes = settings.tabs.map((tab, index) => {
            const rawContentStateText = tab.settings.text
            const markupText = fpDraftToHtml(
              rawContentStateText
            );
            return(
              <div
                key={index}
                className={'tabs-box' + (index === 0 ? ' active': '')}
              >
                <div className="headline h2">
                  <div className="element-text">{tab.settings.headline}</div>
                </div>
                <div className="text">
                  <div dangerouslySetInnerHTML={{ __html: markupText }} />
                </div>
              </div>
            )
          })
        }
        break;
      case 'teasers':
        var teasers = settings.teasers.map((teaser) => {
          return(
            <div key={teaser.id} class={'teaser teaser-' + teaser.settings.type}>
              {
                teaser.settings.type !== 'text' ?
                  <div className="image">
                    <Image id={teaser.settings.image} />
                  </div>
                :
                  ''
              }
              <div className="content">
                <div className="headline h2">
                  <div className="element-text">{teaser.settings.headline}</div>
                </div>
                <div className="text">{teaser.settings.text ? teaser.settings.text.split("\n").map(function(item) {
                  return (
                    <>
                      {item}
                      <br/>
                    </>
                  )
                }) : ''}</div>
              </div>
            </div>
          )
        })
        content = (
          <div className="element-container element-container-teasers">
            <div className="teasers">
              {teasers}
            </div>
          </div>
        )
        break;
      case 'audio':
        content = (
          <div className="element-container element-container-audio">
            <div className="audio">
              <button className="button audio">Mehr Inhalte anhören<Audio id={settings.audio} /></button>
            </div>
          </div>
        )
        break;
      case 'columns':
        var columns = settings.columns.map((column) => {
          var elements = column.settings.elements.map((element) => {
            return(
              <Element
                key={element.id}
                {...element}
              />
            )
          })
          return(
            <div className="column">
              {elements}
            </div>
          )
        })
        content = (
          <div className="element-container element-container-columns">
            <div class="columns">
              {columns}
            </div>
          </div>
        )
        break;
      case 'image':
        content = (
          <div className="element-container element-container-image">
            <div className="image">
              <Image id={settings.image} />
            </div>
          </div>
        )
        break;
      case 'image_zoom':
        content = (
          <div className="element-container element-container-image_zoom">
            <div className="image-zoom">
              <ImageZoom id={settings.image} />
            </div>
          </div>
        )
        break;
      case 'text':
        const rawContentState = this.props.settings.text
        const markup = fpDraftToHtml(
          rawContentState
        );
        content = (
          <div className="element-container element-container-text">
            <div className="text">
              <div className="element-text" style={{textAlign: 'left'}}>
                <div dangerouslySetInnerHTML={{ __html: markup }} />
              </div>
            </div>
          </div>
        )
        break;
      case 'scrollable_text':
        const rawContentStateS = this.props.settings.text
        const markupS = fpDraftToHtml(
          rawContentStateS
        );
        content = (
          <div className="element-container element-container-scrollable_text">
            <div className="scrollable-text">
              <div className="scroll-box">
                <div className="element-text">
                  <div dangerouslySetInnerHTML={{ __html: markupS }} />
                </div>
                </div>
            </div>
          </div>
        )
        break;
      case 'instruction':
        content = (
          <div className="element-container element-container-instruction">
            <div className="instruction">
              <div className="image">
                <Image id={settings.image} />
              </div>
              <div className="content">
                {
                  settings.audio !== '' ?
                    <button className="button audio">Lies mir diesen Text vor<Audio id={settings.audio} /></button>
                  :
                    ''
                }
                <div className="text">
                  <div className="element-text">
                    {settings.text.split('\n').map(function(item, key) {
                      return (
                        <span key={key}>
                          {item}
                          <br/>
                        </span>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        break;
      case 'hotspots':
        var hotspots = settings.hotspots.map((hotspot, index) => {
          return(
            <div class={'poi' + (hotspot.settings.position.x > 50 ? ' inverted' : '')} style={{left: hotspot.settings.position.x + '%', top: hotspot.settings.position.y + '%'}}>
              <div class="poi-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                  <g transform="translate(-9 -9)">
                    <rect width="2" height="12" transform="translate(14 9)"></rect>
                    <rect width="12" height="2" transform="translate(9 14)"></rect>
                  </g>
                </svg>
              </div>
              <div class="poi-text">
                {hotspot.settings.text ? hotspot.settings.text.split("\n").map(function(item) {
                  return (
                    <>
                      {item}
                      <br/>
                    </>
                  )
                }) : ''}
              </div>
            </div>
          )
        })
        content = (
          <div class="element-container element-container-hotspots">
            <div class="hotspots">
              <div class="image">
                <Image id={settings.image} />
              </div>
              <div class="pois">
                {hotspots}
              </div>
            </div>
          </div>
        )
        break;
      case 'lenticular_slider':
        content = (
          <div class="element-container element-container-lenticular_slider">
            <div class="lenticular-slider">
              <div class="images">
                <div class="image image-left"><Image id={settings.image_left} /></div>
                <div class="image image-right"><Image id={settings.image_right} /></div>
              </div>
              <div class="handle react-draggable"></div>
            </div>
          </div>
        )
        break;
      case 'introslider':
        var slidesImages = []
        var slidesContent = []
        if(settings !== undefined && settings.slides !== undefined && settings.slides.length > 0) {
          slidesImages = settings.slides.map((slide, index) => {
            return(
              <div
                key={index}
                className={'slide' + (index === 0 ? ' active': '')}
              >
                <div className="image">
                  <Image
                    id={slide.settings.image}
                  />
                </div>
              </div>
            )
          })
          slidesContent = settings.slides.map((slide, index) => {
            return(
              <div
                key={index}
                className={'slide' + (index === 0 ? ' active': '')}
              >
                <div className="headline h2">
                  <div class="element-text">
                    {slide.settings.headline}
                  </div>
                  <div class="separator"></div>
                </div>
                <hr />
                <div className="text">
                  <div class="element-text">
                    {slide.settings.text ? slide.settings.text.split("\n").map(function(item) {
                      return (
                        <>
                          {item}
                          <br/>
                        </>
                      )
                    }) : ''}
                  </div>
                </div>
              </div>
            )
          })
        }
        content = (
          <div class="element-container element-container-introslider" data-id={this.props.id}>
            <div className="introslider">
              <div className="slider-images">
                <div className="slides">{slidesImages}</div>
              </div>
              <div className="slider-content">
                <div className="slides">{slidesContent}</div>
              </div>
              {
                (settings !== undefined && settings.slides !== undefined && settings.slides.length > 1) ?
                  <div className="slider-arrows">
                    <div
                      className="slider-arrow prev"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="22.245" height="37.42" viewBox="0 0 22.245 37.42">
                        <path d="M-4584.77-12003.848l16.942,16.942,16.942-16.942" transform="translate(-11983.37 4586.537) rotate(90)" strokeWidth="5" />
                      </svg>
                    </div>
                    <div
                      className="slider-arrow next"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="22.245" height="37.42" viewBox="0 0 22.245 37.42">
                        <path d="M-4584.77-12003.848l16.942,16.942,16.942-16.942" transform="translate(12005.615 -4549.117) rotate(-90)" strokeWidth="5" />
                      </svg>
                    </div>
                  </div>
                :
                  ''
              }
            </div>
          </div>
        )
        break;
      case 'slider':
        var slidesImages = settings.slides.map((slide, index) => {
          return(
            <div class={'slide' + (index === 0 ? ' active': '')}>
              <div class="image">
                <Image id={slide.settings.image} />
              </div>
            </div>
          )
        })
        var slidesContent = settings.slides.map((slide, index) => {
          const rawContentState = slide.settings.text
          const markup = fpDraftToHtml(
            rawContentState
          );
          return(
            <div class={'slide' + (index === 0 ? ' active': '')}>
              <div class="separator"></div>
              <div class="separator"></div>
              <div class="headline h2">
                {slide.settings.headline}
              </div>
              <div class="text">
                <div class="element-text">
                  {slide.settings.text ? slide.settings.text.split("\n").map(function(item) {
                    return (
                      <>
                        {item}
                        <br/>
                      </>
                    )
                  }) : ''}
                </div>
              </div>
            </div>
          )
        })
        content = (
          <div class="element-container element-container-slider" data-id={this.props.id}>
            <div class="slider">
              <div class="slider-images">
                <div class="slides">
                  {slidesImages}
                </div>
              </div>
              <div class="slider-content">
                <div class="slides">
                  {slidesContent}
                </div>
              </div>
              {
                settings.slides.length > 1 ?
                  <div class="slider-arrows">
                    <div class="slider-arrow prev">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.718 15.297">
                        <path d="M962.03,228.521l7.646,7.344-7.646,7.229" transform="translate(970.401 243.457) rotate(180)"></path>
                      </svg>
                    </div>
                    <div class="slider-arrow next">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.718 15.297">
                        <path d="M962.03,228.521l7.646,7.344-7.646,7.229" transform="translate(-961.684 -228.159)"></path>
                      </svg>
                    </div>
                  </div>
                :
                  ''
              }
            </div>
          </div>
        )
        break;
      case 'process':
        var slidesContent = settings.steps.map((step, index) => {
          return(
            <div
              key={index}
              className={'slide' + (index === 0 ? ' active' : '')}
            >
              <div className="image">
                <Image
                  id={step.settings.image}
                />
              </div>
              <div className="main">
                <div className="headline h2">
                  {step.settings.headline}
                </div>
                <div className="text">
                  <div class="element-text">
                    {step.settings.text ? step.settings.text.split("\n").map(function(item) {
                      return (
                        <>
                          {item}
                          <br/>
                        </>
                      )
                    }) : ''}
                  </div>
                </div>
              </div>
            </div>
          )
        })
        content = (
          <div class="element-container element-container-process">
            <div className="process">
              <div className="slider-content">
                <div className="slides">{slidesContent}</div>
              </div>
              {
                settings !== undefined && settings.steps !== undefined && settings.steps.length > 0 ?
                  <div className="dots-wrapper">
                    <div className="dots">
                      {
                        settings.steps.map((step, index) => {
                          return (
                            <div
                              key={index}
                              className={'dot' + (index === 0 ? ' active' : '')}
                            >
                            </div>
                          )
                        })
                      }
                    </div>
                    <div className="arrow-right">
                      <svg xmlns="http://www.w3.org/2000/svg" width="9.201" height="16.849" viewBox="0 0 9.201 16.849">
                        <path id="Rechteck_9_Kopie" data-name="Rechteck 9 Kopie" d="M7.93,16,0,8,7.93,0" transform="translate(8.356 16.427) rotate(180)" fill="none" stroke="#fff" stroke-width="1.2"/>
                      </svg>
                    </div>
                  </div>
                : null
              }
            </div>
          </div>
        )
        break;
      case 'drag_and_drop':
        var cards = settings.cards;
        content = (
          <div className={'element-container element-container-drag_and_drop' + (settings.required_question ? ' required_question' : '')} data-id={this.props.id}>
            <div className="drag-and-drop">
              <ElementBox
                {...this.props}
              />
              <div className="areas">
                <div className="areas-column">
                  {
                    settings.fields.map((field, index) => {
                      var value = field.settings.field
                      if(index % 2 === 0) {
                        return(
                          <div
                            key={field.id}
                            className="area field"
                            data-index={index}
                          >
                            <div className="area-header">
                              {value}
                            </div>
                            <div className="area-body dropzone"></div>
                          </div>
                        )
                      } else {
                        return null
                      }
                    })
                  }
                </div>
                <div className="areas-column">
                  <div className="area cards dropzone">
                    <div className="buttons">
                      <div className="button resolve">Zur Auflösung</div>
                    </div>
                    {
                      settings.cards.slice(0).reverse().map((card, index) => {
                        var value = card.settings.text ? card.settings.text.split("\n").map(function(item) {
                          return (
                            <>
                              {item}
                              <br/>
                            </>
                          )
                        }) : ''
                        var field = parseInt(card.settings.field)
                        if(isNaN(field)) {
                          field = 0
                        }
                        return(
                          <div
                            key={card.id}
                            className="card"
                            style={{cursor: 'move'}}
                            data-option={field}
                          >
                            {value}
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="areas-column">
                  {
                    settings.fields.map((field, index) => {
                      var value = field.settings.field
                      if(index % 2 === 1) {
                        return(
                          <div
                            key={field.id}
                            className="area field"
                            data-index={index}
                          >
                            <div className="area-header">
                              {value}
                            </div>
                            <div className="area-body dropzone"></div>
                          </div>
                        )
                      } else {
                        return null
                      }
                    })
                  }
                </div>
              </div>
							<div class="solution">
                <div class="headline">Deine Auswahl</div>
								<div class="cards user-solution"></div>
								<div class="headline">Musterlösung</div>
								<div class="cards master-solution">
                  {
                    settings.cards.map((card, index) => {
                      var value = card.settings.text ? card.settings.text.split("\n").map(function(item) {
                        return (
                          <>
                            {item}
                            <br/>
                          </>
                        )
                      }) : ''
                      var field = parseInt(card.settings.field)
                      if(isNaN(field)) {
                        field = 0
                      }
                      return(
                        <div
                          key={card.id}
                          className="card"
                        >
                          <div className="field">{settings.fields[field].settings.field}</div>
                          <div className="text">{value}</div>
                        </div>
                      )
                    })
                  }
								</div>
							</div>
            </div>
          </div>
        )
        break;
      case 'multiple_choice':
        var answers = settings.answers.map((answer, index) => {
          var solution
          if(answer.settings.field == 'Teilweise richtig') {
            solution = 'partly-correct'
          } else if(answer.settings.field == 'Falsch') {
            solution = 'wrong'
          } else {
            solution = 'correct'
          }
          return(
            <div className="button answer-item no-hover" style={{cursor: 'pointer'}}>
              <div className="text">{answer.settings.text}</div>
              <div class="solution">
                {
                  solution !== 'wrong' ?
                    <div class="icon correct">
      								<svg xmlns="http://www.w3.org/2000/svg" width="9.445" height="11.452" viewBox="0 0 9.445 11.452">
      								  <path d="M6556.67,2596.9h3.907v-9.391" transform="translate(-4379.294 -5519.182) rotate(30)" stroke-width="2" />
      								</svg>
    		           </div>
                  :
                    ''
                }
                {
                  solution !== 'correct' ?
                    <div class="icon wrong">
    									<svg xmlns="http://www.w3.org/2000/svg" width="9.899" height="9.899" viewBox="0 0 9.899 9.899">
    									  <g transform="translate(-9800.05 -2089.05)">
    									    <path d="M9809.949,2097.535l-1.414,1.414-8.485-8.485,1.415-1.414Z" />
    									    <path d="M9809.949,2090.465l-8.484,8.485-1.415-1.414,8.485-8.485Z" />
    									  </g>
    									</svg>
    								</div>
                  :
                    ''
                }
              </div>
            </div>
          )
        })
        var answersList = (
          <div className="answers-list">
            {answers}
          </div>
        )
        content = (
          <div className={'element-container element-container-multiple_choice' + (settings.required_question ? ' required_question' : '')}>
            <div className="multiple-choice">
              <ElementBox
                {...this.props}
              />
              {answersList}
              <div className="buttons">
                <div className="button resolve">Zur Auflösung</div>
              </div>
            </div>
          </div>
        )
        break;
      case 'profile':
        var ratings = []
        var rows = []
        if(settings !== undefined && settings.ratings !== undefined && settings.ratings.length > 0) {
          ratings = settings.ratings.map((rating, index) => {
            return(
              <th>
                {rating.settings.text}
              </th>
            )
          })
        }
        if(settings !== undefined && settings.rows !== undefined && settings.rows.length > 0) {
          rows = settings.rows.map((row, index) => {
            return(
              <tr>
                <td>
                  {row.settings.text}
                </td>
                {
                  ratings.map((rating, i) => (
                    <td>
                      <input type="radio" name={'radio_' + row.id} id={'radio_' + row.id + '_' + i} value={i} />
                      <label htmlFor={'radio_' + row.id + '_' + i}></label>
                    </td>
                  ))
                }
              </tr>
            )
          })
        }
        content = (
          <div className="element-container element-container-profile">
            <div className="multiple-choice">
              <ElementBox
                {...this.props}
              />
              <div className="profile-box">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      {
                        ratings
                      }
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
            </div>
          </div>
        )
        break;
      case 'hotspots_quiz':
        content = (
          <div className="element-container element-container-hotspots_quiz">
            <div className="hotspots-quiz">
              <div className="hotspots">
                <div className="pois-wrapper">
                  <div className="image">
                    <Image
                      id={settings.image}
                    />
                  </div>
                </div>
              </div>
              <ElementBox
                {...this.props}
              />
              <div className="buttons">
                <div className="button resolve">Zur Auflösung</div>
              </div>
            </div>
          </div>
        )
        break;
      case 'chat_simulator':
        content = (
          <div className="element-container element-container-chat_simulator">
            <div className="chat-simulator">
              <div className="chat-history">
                {
                  settings.messages.map((message, index) => {
                    return (
                      <div className={'chat-section' + (index > 0 ? ' inactive' : '')}>
                        <div className="chat-message">
                          <div className="avatar">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.742" height="15.908" viewBox="0 0 15.742 15.908">
                              <g id="Gruppe_1642" data-name="Gruppe 1642" transform="translate(-448 -186.908)">
                                <g id="Ellipse_41" data-name="Ellipse 41" transform="translate(450.871 186.908)" fill="none" stroke="#fff" stroke-width="1" >
                                  <circle cx="5" cy="5" r="5" stroke="none" />
                                  <circle cx="5" cy="5" r="4.5" fill="none" />
                                </g>
                                <g id="Pfad_930" data-name="Pfad 930" transform="translate(448 196.377)" fill="none">
                                  <path d="M7.871,0c4.347,0,7.871,2.883,7.871,6.44H0C0,2.883,3.524,0,7.871,0Z" stroke="none"/>
                                  <path d="M 7.871093273162842 1.000002861022949 C 4.513636589050293 1.000002861022949 1.710929870605469 2.916239500045776 1.11635684967041 5.439982891082764 L 14.62582969665527 5.439982891082764 C 14.03125667572021 2.916239500045776 11.22854995727539 1.000002861022949 7.871093273162842 1.000002861022949 M 7.871093273162842 2.86102294921875e-06 C 12.21818351745605 2.86102294921875e-06 15.74218368530273 2.883282899856567 15.74218368530273 6.439982891082764 L 2.86102294921875e-06 6.439982891082764 C 2.86102294921875e-06 2.883282899856567 3.524013519287109 2.86102294921875e-06 7.871093273162842 2.86102294921875e-06 Z" stroke="none" fill="#fff"/>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="message">
                            {
                              message.settings.image ?
                                <Image
                                  id={message.settings.image}
                                />
                              : null
                            }
                            {message.settings.text ? message.settings.text.split("\n").map(function(item) {
                              return (
                                <>
                                  {item}
                                  <br/>
                                </>
                              )
                            }) : ''}
                          </div>
                        </div>
                        <div className="chat-message chat-message-me">
                          <div className="avatar">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.742" height="15.908" viewBox="0 0 15.742 15.908">
                              <g id="Gruppe_1642" data-name="Gruppe 1642" transform="translate(-448 -186.908)">
                                <g id="Ellipse_41" data-name="Ellipse 41" transform="translate(450.871 186.908)" fill="none" stroke="#fff" stroke-width="1" >
                                  <circle cx="5" cy="5" r="5" stroke="none" />
                                  <circle cx="5" cy="5" r="4.5" fill="none" />
                                </g>
                                <g id="Pfad_930" data-name="Pfad 930" transform="translate(448 196.377)" fill="none">
                                  <path d="M7.871,0c4.347,0,7.871,2.883,7.871,6.44H0C0,2.883,3.524,0,7.871,0Z" stroke="none"/>
                                  <path d="M 7.871093273162842 1.000002861022949 C 4.513636589050293 1.000002861022949 1.710929870605469 2.916239500045776 1.11635684967041 5.439982891082764 L 14.62582969665527 5.439982891082764 C 14.03125667572021 2.916239500045776 11.22854995727539 1.000002861022949 7.871093273162842 1.000002861022949 M 7.871093273162842 2.86102294921875e-06 C 12.21818351745605 2.86102294921875e-06 15.74218368530273 2.883282899856567 15.74218368530273 6.439982891082764 L 2.86102294921875e-06 6.439982891082764 C 2.86102294921875e-06 2.883282899856567 3.524013519287109 2.86102294921875e-06 7.871093273162842 2.86102294921875e-06 Z" stroke="none" fill="#fff"/>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div class="answers">
                            {
                              message.settings.answer_1 ? (
                                <div class="answer">
                                  <input type="radio" name={'radio_' + message.id} id={'radio_' + message.id + '_1'} value="1" />
                                  <label className="message" htmlFor={'radio_' + message.id + '_1'}>
                                    {message.settings.answer_1 ? message.settings.answer_1.split("\n").map(function(item) {
                                      return (
                                        <>
                                          {item}
                                          <br/>
                                        </>
                                      )
                                    }) : ''}
                                    <div className="check-mark">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="21.061" height="14.924" viewBox="0 0 21.061 14.924">
                                        <path id="Pfad_785" data-name="Pfad 785" d="M3262,3691.667l6.667,6.666L3282,3685" transform="translate(-3261.47 -3684.47)" fill="none" stroke="#fff" stroke-width="1.5"/>
                                      </svg>
                                    </div>
                                  </label>
                                </div>
                              ) : ''
                            }
                            {
                              message.settings.answer_2 ? (
                                <div class="answer">
                                  <input type="radio" name={'radio_' + message.id} id={'radio_' + message.id + '_2'} value="2" />
                                  <label className="message" htmlFor={'radio_' + message.id + '_2'}>
                                    {message.settings.answer_2 ? message.settings.answer_2.split("\n").map(function(item) {
                                      return (
                                        <>
                                          {item}
                                          <br/>
                                        </>
                                      )
                                    }) : ''}
                                    <div className="check-mark">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="21.061" height="14.924" viewBox="0 0 21.061 14.924">
                                        <path id="Pfad_785" data-name="Pfad 785" d="M3262,3691.667l6.667,6.666L3282,3685" transform="translate(-3261.47 -3684.47)" fill="none" stroke="#fff" stroke-width="1.5"/>
                                      </svg>
                                    </div>
                                  </label>
                                </div>
                              ) : ''
                            }
                            {
                              message.settings.answer_3 ? (
                                <div class="answer">
                                  <input type="radio" name={'radio_' + message.id} id={'radio_' + message.id + '_3'} value="3" />
                                  <label className="message" htmlFor={'radio_' + message.id + '_3'}>
                                    {message.settings.answer_3 ? message.settings.answer_3.split("\n").map(function(item) {
                                      return (
                                        <>
                                          {item}
                                          <br/>
                                        </>
                                      )
                                    }) : ''}
                                    <div className="check-mark">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="21.061" height="14.924" viewBox="0 0 21.061 14.924">
                                        <path id="Pfad_785" data-name="Pfad 785" d="M3262,3691.667l6.667,6.666L3282,3685" transform="translate(-3261.47 -3684.47)" fill="none" stroke="#fff" stroke-width="1.5"/>
                                      </svg>
                                    </div>
                                  </label>
                                </div>
                              ) : ''
                            }
                          </div>
                          <button className="button">Bestätigen</button>
                        </div>
                        {
                          message.settings.message_1 ? (
                            <div className="chat-message answer-message answer-message-1">
                              <div className="avatar">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15.742" height="15.908" viewBox="0 0 15.742 15.908">
                                  <g id="Gruppe_1642" data-name="Gruppe 1642" transform="translate(-448 -186.908)">
                                    <g id="Ellipse_41" data-name="Ellipse 41" transform="translate(450.871 186.908)" fill="none" stroke="#fff" stroke-width="1" >
                                      <circle cx="5" cy="5" r="5" stroke="none" />
                                      <circle cx="5" cy="5" r="4.5" fill="none" />
                                    </g>
                                    <g id="Pfad_930" data-name="Pfad 930" transform="translate(448 196.377)" fill="none">
                                      <path d="M7.871,0c4.347,0,7.871,2.883,7.871,6.44H0C0,2.883,3.524,0,7.871,0Z" stroke="none"/>
                                      <path d="M 7.871093273162842 1.000002861022949 C 4.513636589050293 1.000002861022949 1.710929870605469 2.916239500045776 1.11635684967041 5.439982891082764 L 14.62582969665527 5.439982891082764 C 14.03125667572021 2.916239500045776 11.22854995727539 1.000002861022949 7.871093273162842 1.000002861022949 M 7.871093273162842 2.86102294921875e-06 C 12.21818351745605 2.86102294921875e-06 15.74218368530273 2.883282899856567 15.74218368530273 6.439982891082764 L 2.86102294921875e-06 6.439982891082764 C 2.86102294921875e-06 2.883282899856567 3.524013519287109 2.86102294921875e-06 7.871093273162842 2.86102294921875e-06 Z" stroke="none" fill="#fff"/>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="message">
                                {message.settings.message_1 ? message.settings.message_1.split("\n").map(function(item) {
                                  return (
                                    <>
                                      {item}
                                      <br/>
                                    </>
                                  )
                                }) : ''}
                              </div>
                            </div>
                          ) : ''
                        }
                        {
                          message.settings.message_2 ? (
                            <div className="chat-message answer-message answer-message-2">
                              <div className="avatar">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15.742" height="15.908" viewBox="0 0 15.742 15.908">
                                  <g id="Gruppe_1642" data-name="Gruppe 1642" transform="translate(-448 -186.908)">
                                    <g id="Ellipse_41" data-name="Ellipse 41" transform="translate(450.871 186.908)" fill="none" stroke="#fff" stroke-width="1" >
                                      <circle cx="5" cy="5" r="5" stroke="none" />
                                      <circle cx="5" cy="5" r="4.5" fill="none" />
                                    </g>
                                    <g id="Pfad_930" data-name="Pfad 930" transform="translate(448 196.377)" fill="none">
                                      <path d="M7.871,0c4.347,0,7.871,2.883,7.871,6.44H0C0,2.883,3.524,0,7.871,0Z" stroke="none"/>
                                      <path d="M 7.871093273162842 1.000002861022949 C 4.513636589050293 1.000002861022949 1.710929870605469 2.916239500045776 1.11635684967041 5.439982891082764 L 14.62582969665527 5.439982891082764 C 14.03125667572021 2.916239500045776 11.22854995727539 1.000002861022949 7.871093273162842 1.000002861022949 M 7.871093273162842 2.86102294921875e-06 C 12.21818351745605 2.86102294921875e-06 15.74218368530273 2.883282899856567 15.74218368530273 6.439982891082764 L 2.86102294921875e-06 6.439982891082764 C 2.86102294921875e-06 2.883282899856567 3.524013519287109 2.86102294921875e-06 7.871093273162842 2.86102294921875e-06 Z" stroke="none" fill="#fff"/>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="message">
                                {message.settings.message_2 ? message.settings.message_2.split("\n").map(function(item) {
                                  return (
                                    <>
                                      {item}
                                      <br/>
                                    </>
                                  )
                                }) : ''}
                              </div>
                            </div>
                          ) : ''
                        }
                        {
                          message.settings.message_3 ? (
                            <div className="chat-message answer-message answer-message-3">
                              <div className="avatar">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15.742" height="15.908" viewBox="0 0 15.742 15.908">
                                  <g id="Gruppe_1642" data-name="Gruppe 1642" transform="translate(-448 -186.908)">
                                    <g id="Ellipse_41" data-name="Ellipse 41" transform="translate(450.871 186.908)" fill="none" stroke="#fff" stroke-width="1" >
                                      <circle cx="5" cy="5" r="5" stroke="none" />
                                      <circle cx="5" cy="5" r="4.5" fill="none" />
                                    </g>
                                    <g id="Pfad_930" data-name="Pfad 930" transform="translate(448 196.377)" fill="none">
                                      <path d="M7.871,0c4.347,0,7.871,2.883,7.871,6.44H0C0,2.883,3.524,0,7.871,0Z" stroke="none"/>
                                      <path d="M 7.871093273162842 1.000002861022949 C 4.513636589050293 1.000002861022949 1.710929870605469 2.916239500045776 1.11635684967041 5.439982891082764 L 14.62582969665527 5.439982891082764 C 14.03125667572021 2.916239500045776 11.22854995727539 1.000002861022949 7.871093273162842 1.000002861022949 M 7.871093273162842 2.86102294921875e-06 C 12.21818351745605 2.86102294921875e-06 15.74218368530273 2.883282899856567 15.74218368530273 6.439982891082764 L 2.86102294921875e-06 6.439982891082764 C 2.86102294921875e-06 2.883282899856567 3.524013519287109 2.86102294921875e-06 7.871093273162842 2.86102294921875e-06 Z" stroke="none" fill="#fff"/>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="message">
                                {message.settings.message_3 ? message.settings.message_3.split("\n").map(function(item) {
                                  return (
                                    <>
                                      {item}
                                      <br/>
                                    </>
                                  )
                                }) : ''}
                              </div>
                            </div>
                          ) : ''
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        )
        break;
      case 'video':
        content = (
          <div className="element-container element-container-video">
            <div className="video">
              <Video id={settings.video} />
            </div>
          </div>
        )
        break;
      case 'free_text_field':
        content = (
          <div className="element-container element-container-free_text_field">
            <div className="free-text-field">
              <ElementBox
                {...this.props}
              />
              <textarea className="input-field" placeholder={settings.placeholder} style={{border: 'none'}}>
              </textarea>
            </div>
          </div>
        )
        break;
      case 'slide_control':
        content = (
          <div className="element-container element-container-slide_control">
            <div className="slide-control">
              <ElementBox
                {...this.props}
              />
              {
                settings.image !== '' ?
                  <div class="image">
                    <Image id={settings.image} />
                  </div>
                :
                  ''
              }
              <div class="control">
                <div class="point point-left">{settings.point_left}</div>
                <div class="input">
                  <div class="rail">
                    <div class="handle"></div>
                  </div>
                </div>
                <div class="point point-right">{settings.point_right}</div>
              </div>
              <div class="buttons">
                <div class="button resolve">Bestätigen</div>
              </div>
            </div>
          </div>
        )
        break;
      case 'pairs':
        var buttons = []
        var pairs = settings.pairs.map((pair, index) => {
          buttons.push({
            text: pair.settings.text_1,
            pair_index: index
          });
          buttons.push({
            text: pair.settings.text_2,
            pair_index: index
          });
        })
        buttons.sort(() => Math.random() - 0.5)
        var pairsList = buttons.map((button, index) => {
          return(
            <div className="button pair-item no-hover" data-pair-id={button.pair_index} style={{cursor: 'pointer'}}>
              {button.text}
            </div>
          )
        })
        var solutions = settings.pairs.map((pair, index) => {
          return(<div class="selected-pair">
            <div class="button pair-item no-hover">{pair.settings.text_1}</div>
            <div class="button pair-item no-hover">{pair.settings.text_2}</div>
          </div>)
        })
        content = (
          <div className={'element-container element-container-pairs' + (settings.required_question ? ' required_question' : '')}>
            <div className="pairs">
              <ElementBox
                {...this.props}
              />
              <div className="pairs-list">
                <div className="pair-items">
                  {pairsList}
                </div>
								<div class="selected-pairs"></div>
              </div>
							<div class="solution">
								<div class="headline">
									Deine Auswahl
			           </div>
								<div class="solution-pairs user-solution"></div>
								<div class="headline">Musterlösung</div>
								<div class="solution-pairs master-solution">
									{solutions}
								</div>
							</div>
              <div className="buttons">
                <div className="button resolve">Zur Auflösung</div>
              </div>
            </div>
          </div>
        )
        break;
      default:
        content = this.props.type
    }
    return(
      <div className="element">
        {content}
      </div>
    )
  }
}

export default Element
