import React, {useContext, useEffect} from 'react'
import Frame, {FrameContext, FrameContextConsumer} from 'react-frame-component'
import {DndContext} from 'react-dnd'

import Wbt from './wbt/Wbt'
import Modules from './wbt/modules/Modules'
import Overlay from './wbt/overlay/Overlay'
import ExportPreview from './wbt/export-preview/ExportPreview'

const DndFrame = ({children}) => {
  const {dragDropManager} = useContext(DndContext)
  const {window} = useContext(FrameContext)

  useEffect(() => {
    dragDropManager.getBackend().addEventListeners(window)
  })

  return children
}

class Preview extends React.Component {
  constructor(props) {
    super(props)

    this.included = false
  }

  render() {
    return(
      <div id="editor-preview" className={this.props.previewMode ? 'preview-mode' : ''}>
        <div className={'responsive-wrapper' + (this.props.responsiveMode ? ' ' + this.props.responsiveMode : '' )}>
          <Frame head={
            <React.Fragment>
              <link rel="stylesheet" href="/dist/wbt/styles/main.min.css?v=13.8" />
              <link rel="stylesheet" href="/dist/styles/editor.min.css?v=13.8" />
            </React.Fragment>
          }>
          <FrameContextConsumer>
            {
              ({document, window}) => {
                return(
                  <DndFrame>
                    {
                      this.props.previewMode !== undefined && this.props.previewMode === true ?
                        <ExportPreview
                          document={document}
                          {...this.props}
                        />
                      :
                        <Wbt
                          {...this.props}
                        >
                          <Modules
                            {...this.props}
                            modules={this.props.wbt.modules}
                          />
                          <Overlay
                            {...this.props}
                            overlayId={this.props.overlayId}
                          />
                        </Wbt>
                      }
                  </DndFrame>
                  )
                }
              }
            </FrameContextConsumer>
          </Frame>
        </div>
      </div>
    )
  }
}

export default Preview
