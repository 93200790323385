import React from 'react'

import {Editor, EditorState, ContentState, convertFromRaw, convertToRaw} from 'draft-js'
import draftToHtml from 'draftjs-to-html';

import Audio from './Audio'
import Image from './Image'
import ElementBox from './ElementBox'
//import Video from '../../modules/elements/components/Video'

import CmsHelper from '../../../../../helpers/Cms'

function fpDraftToHtml(rawContentState) {
  if(rawContentState && rawContentState.blocks) {
    rawContentState.blocks.map((block, i) => {
      if(block.type === 'paragraph') {
        rawContentState.blocks[i].type = 'unstyled'
      }
    })
  }
  return draftToHtml(rawContentState);
}

class Element extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var content = ''
    var settings = this.props.element !== undefined ? this.props.element.settings : {}
    if(this.props.element !== undefined) {
      switch(this.props.element.type) {
        case 'accordion':
          var sections = settings.sections.map((section) => {
            const rawContentState = section.settings.text
            const markup = draftToHtml(
              rawContentState
            );
            return(
              <div key={section.id} className="section">
                <div className="section-head" style={{cursor: 'pointer'}}>
                  <div className="headline h2">
                    <div className="element-text">
                      {section.settings.headline}
                    </div>
                    <div className="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16.858" height="8.479" viewBox="0 0 16.858 8.479"><path d="M4332.7,2592.82l-7.432-8.091,7.432-8.091" transform="translate(-2576.301 4333.072) rotate(-90)" strokeWidth="1"></path></svg>
                    </div>
                  </div>
                </div>
                <div className="section-body">
                  <div className="element-text">
                    <div dangerouslySetInnerHTML={{ __html: markup }} />
                  </div>
                </div>
              </div>
            )
          })
          content = (
            <div className="element-container element-container-accordion">
              <div className="accordion">
                <div className="sections">
                  {
                    sections
                  }
                </div>
              </div>
            </div>
          )
          break;
        case 'tabs':
          var tabsButtons = []
          var tabsBoxes = []
          if(settings !== undefined && settings.tabs !== undefined && settings.tabs.length > 0) {
            tabsButtons = settings.tabs.map((tab, index) => {
              return(
                <div
                  key={index}
                  className={'tabs-button' + (index === 0 ? ' active': '')}
                >
                  <div className="element-text">{tab.settings.headline}</div>
                </div>
              )
            })
            tabsBoxes = settings.tabs.map((tab, index) => {
              const rawContentStateText = tab.settings.text
              const markupText = fpDraftToHtml(
                rawContentStateText
              );
              return(
                <div
                  key={index}
                  className={'tabs-box' + (index === 0 ? ' active': '')}
                >
                  <div className="headline h2">
                    <div className="element-text">{tab.settings.headline}</div>
                  </div>
                  <div className="text">
                    <div dangerouslySetInnerHTML={{ __html: markupText }} />
                  </div>
                </div>
              )
            })
          }
          content = (
            <div className="element-container element-container-tabs">
              <div className="tabs">
                <div className="tabs-buttons">
                  {tabsButtons}
                </div>
                <div className="tabs-boxes">
                  {tabsBoxes}
                </div>
              </div>
            </div>
          )
          break;
        case 'flashcards':
          var cards = []
          if(settings !== undefined && settings.cards !== undefined && settings.cards.length > 0) {
            cards = settings.cards.map((card, index) => {
              const rawContentStateTextFront = card.settings.text_front
              const markupTextFront = fpDraftToHtml(
                rawContentStateTextFront
              );
                const rawContentStateTextBack = card.settings.text_back
                const markupTextBack = fpDraftToHtml(
                  rawContentStateTextBack
                );
              return(
                <div
                  key={index}
                  className='card'
                >
                  <div className="card-inner">
                    <div className="card-front">
                      <div className="headline h2">
                        <div className="element-text">{card.settings.headline_front}</div>
                      </div>
                      <div className="text">
                        <div dangerouslySetInnerHTML={{ __html: markupTextFront }} />
                      </div>
                    </div>
                    <div className="card-back">
                      <div className="headline h2">
                        <div className="element-text">{card.settings.headline_back}</div>
                      </div>
                      <div className="text">
                        <div dangerouslySetInnerHTML={{ __html: markupTextBack }} />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
          content = (
            <div className="element-container element-container-flashcards">
              <div className="flashcards">
                {cards}
              </div>
            </div>
          )
          break;
        case 'teasers':
          var teasers = settings.teasers.map((teaser) => {
            return(
              <div key={teaser.id} class={'teaser teaser-' + teaser.settings.type}>
                {
                  teaser.settings.type !== 'text' ?
                    <div className="image">
                      <Image id={teaser.settings.image} element={this.props.element} urls={this.props.urls} />
                    </div>
                  :
                    ''
                }
                <div className="content">
                  <div className="headline h2">
                    <div className="element-text">{teaser.settings.headline}</div>
                  </div>
                  <div className="text">{teaser.settings.text}</div>
                </div>
              </div>
            )
          })
          content = (
            <div className="element-container element-container-teasers">
              <div className="teasers">
                {teasers}
              </div>
            </div>
          )
          break;
        case 'audio':
          content = (
            <div className="element-container element-container-audio">
              <div className="audio">
                <button className="button audio">Mehr Inhalte anhören<Audio element={this.props.element} urls={this.props.urls} /></button>
              </div>
            </div>
          )
          break;
        case 'columns':
          var columns = settings.columns.map((column) => {
            var elements = column.settings.elements.map((element) => {
              return(
                <Element
                  key={element.id}
                  {...element}
                />
              )
            })
            return(
              <div className="column">
                {elements}
              </div>
            )
          })
          content = (
            <div className="element-container element-container-columns">
              <div class="columns">
                {columns}
              </div>
            </div>
          )
          break;
        case 'image':
          content = (
            <div className="element-container element-container-image">
              <div className="image">
                <Image id={settings.image} element={this.props.element} urls={this.props.urls} />
              </div>
            </div>
          )
          break;
        case 'text':
          const rawContentState = this.props.element.settings.text
          const markup = draftToHtml(
            rawContentState
          );
          content = (
            <div className="element-container element-container-text">
              <div className="text">
                <div className="element-text">
                  <div dangerouslySetInnerHTML={{ __html: markup }} />
                </div>
              </div>
            </div>
          )
          break;
        case 'instruction':
          content = (
            <div className="element-container element-container-instruction">
              <div className="instruction">
                <div className="image">
                  <Image element={this.props.element} urls={this.props.urls} />
                </div>
                <div className="content">
                  {
                    settings.audio !== '' ?
                      <button className="button audio">Lies mir diesen Text vor<Audio id={settings.audio} element={this.props.element} urls={this.props.urls} /></button>
                    :
                      ''
                  }
                  <div className="text">
                    <div className="element-text">
                      {settings.text.split('\n').map(function(item, key) {
                        return (
                          <span key={key}>
                            {item}
                            <br/>
                          </span>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
          break;
        case 'hotspots_quiz':
          var hotspots = settings.hotspots.map((hotspot, index) => {
            return(
              <div className={'poi' + (hotspot.settings.position.x > 50 ? ' inverted' : '')} style={{left: hotspot.settings.position.x + '%', top: hotspot.settings.position.y + '%'}} data-index={index}>
                <div className="poi-button">
                  <div className="icon icon-plus">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                      <g transform="translate(-9 -9)">
                        <rect width="2" height="12" transform="translate(14 9)"></rect>
                        <rect width="12" height="2" transform="translate(9 14)"></rect>
                      </g>
                    </svg>
                  </div>
                  <div className="icon icon-minus">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="2" viewBox="0 0 12 2">
                      <rect width="12" height="2"/>
                    </svg>
                  </div>
                  <div className="icon icon-check">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.758" height="10.654" viewBox="0 0 13.758 10.654">
                      <path d="M17335.91-1910.272l3.813,3.81,8.531-8.533" transform="translate(-17335.203 1915.701)" fill="none" stroke="#fff" stroke-width="2"/>
                    </svg>
                  </div>
                  <div className="icon icon-x">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" style={{transform: 'rotate(45deg)'}}>
                      <g transform="translate(-9 -9)">
                        <rect width="2" height="12" transform="translate(14 9)"></rect>
                        <rect width="12" height="2" transform="translate(9 14)"></rect>
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="poi-text">
                  {hotspot.settings.text ? hotspot.settings.text.split("\n").map(function(item) {
                    return (
                      <>
                        {item}
                        <br/>
                      </>
                    )
                  }) : ''}
                </div>
              </div>
            )
          })
          content = (
            <div className="element-container element-container-hotspots_quiz">
              <div className="hotspots-quiz">
                <div className="hotspots">
                  <div className="pois-wrapper">
                    <div className="image">
                      <Image id={settings.image} element={this.props.element} urls={this.props.urls} />
                      <div class="pois">
                        {hotspots}
                      </div>
                      <div className="pois-options">
                        {
                          settings.hotspots.map((hotspot, index) => {
                            return (
                              <div className="pois-option" data-index={index}>
                                {
                                  hotspot.settings.answer_1 ? (
                                    <div className={'answer' + (hotspot.settings.solution == '1' ? ' correct' : '')}>{hotspot.settings.answer_1}</div>
                                  ) : null
                                }
                                {
                                  hotspot.settings.answer_2 ? (
                                    <div className={'answer' + (hotspot.settings.solution == '2' ? ' correct' : '')}>{hotspot.settings.answer_2}</div>
                                  ) : null
                                }
                                {
                                  hotspot.settings.answer_3 ? (
                                    <div className={'answer' + (hotspot.settings.solution == '3' ? ' correct' : '')}>{hotspot.settings.answer_3}</div>
                                  ) : null
                                }
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <ElementBox
                  {...this.props.element}
                  element={this.props.element} urls={this.props.urls}
                />
              </div>
            </div>
          )
          break;
        case 'hotspots':
          var hotspots = settings.hotspots.map((hotspot, index) => {
            return(
              <div class={'poi' + (hotspot.settings.position.x > 50 ? ' inverted' : '')} style={{left: hotspot.settings.position.x + '%', top: hotspot.settings.position.y + '%'}}>
                <div class="poi-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                    <g transform="translate(-9 -9)">
                      <rect width="2" height="12" transform="translate(14 9)"></rect>
                      <rect width="12" height="2" transform="translate(9 14)"></rect>
                    </g>
                  </svg>
                </div>
                <div class="poi-text">{hotspot.settings.text}</div>
              </div>
            )
          })
          content = (
            <div class="element-container element-container-hotspots">
              <div class="hotspots">
                <div class="image">
                  <Image id={settings.image} element={this.props.element} urls={this.props.urls} />
                </div>
                <div class="pois">
                  {hotspots}
                </div>
              </div>
            </div>
          )
          break;
        case 'lenticular_slider':
          content = (
            <div class="element-container element-container-lenticular_slider">
              <div class="lenticular-slider">
                <div class="images">
                  <div class="image image-left"><Image id={settings.image_left} element={this.props.element} urls={this.props.urls} url={this.props.urls[this.props.element.id + '_image_left']} /></div>
                  <div class="image image-right"><Image id={settings.image_right} element={this.props.element} urls={this.props.urls} url={this.props.urls[this.props.element.id + '_image_right']} /></div>
                </div>
                <div class="handle react-draggable"></div>
              </div>
            </div>
          )
          break;
        case 'introslider':
          var slidesImages = []
          var slidesContent = []
          if(settings !== undefined && settings.slides !== undefined && settings.slides.length > 0) {
            slidesImages = settings.slides.map((slide, index) => {
              return(
                <div
                  key={index}
                  className={'slide' + (index === 0 ? ' active': '')}
                >
                  <div className="image">
                    <Image
                      id={slide.settings.image} element={this.props.element} urls={this.props.urls}
                     url={this.props.urls[slide.id + '_image']}
                    />
                  </div>
                </div>
              )
            })
            slidesContent = settings.slides.map((slide, index) => {
              return(
                <div
                  key={index}
                  className={'slide' + (index === 0 ? ' active': '')}
                >
                  <div className="headline h2">
                    <div class="element-text">
                      {slide.settings.headline}
                    </div>
                    <div class="separator"></div>
                  </div>
                  <hr />
                  <div className="text">
                    <div class="element-text">
                      {slide.settings.text}
                    </div>
                  </div>
                </div>
              )
            })
          }
          content = (
            <div class="element-container element-container-introslider" data-id={this.props.element.id}>
              <div className="introslider">
                <div className="slider-images">
                  <div className="slides">{slidesImages}</div>
                </div>
                <div className="slider-content">
                  <div className="slides">{slidesContent}</div>
                </div>
                {
                  (settings !== undefined && settings.slides !== undefined && settings.slides.length > 1) ?
                    <div className="slider-arrows">
                      <div
                        className="slider-arrow prev"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="22.245" height="37.42" viewBox="0 0 22.245 37.42">
                          <path d="M-4584.77-12003.848l16.942,16.942,16.942-16.942" transform="translate(-11983.37 4586.537) rotate(90)" strokeWidth="5" />
                        </svg>
                      </div>
                      <div
                        className="slider-arrow next"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="22.245" height="37.42" viewBox="0 0 22.245 37.42">
                          <path d="M-4584.77-12003.848l16.942,16.942,16.942-16.942" transform="translate(12005.615 -4549.117) rotate(-90)" strokeWidth="5" />
                        </svg>
                      </div>
                    </div>
                  :
                    ''
                }
              </div>
            </div>
          )
          break;
        case 'slider':
          var slidesImages = settings.slides.map((slide, index) => {
            return(
              <div class={'slide' + (index === 0 ? ' active': '')}>
                <div class="image">
                  <Image id={slide.settings.image} element={this.props.element} urls={this.props.urls} url={this.props.urls[slide.id + '_image']} />
                </div>
              </div>
            )
          })
          var slidesContent = settings.slides.map((slide, index) => {
            return(
              <div class={'slide' + (index === 0 ? ' active': '')}>
                <div class="separator"></div>
                <div class="separator"></div>
                <div class="headline h2">
                  {slide.settings.headline}
                </div>
                <div class="text">
                  <div class="element-text">
                    {slide.settings.text}
                  </div>
                </div>
              </div>
            )
          })
          content = (
            <div class="element-container element-container-slider" data-id={this.props.element.id}>
              <div class="slider">
                <div class="slider-images">
                  <div class="slides">
                    {slidesImages}
                  </div>
                </div>
                <div class="slider-content">
                  <div class="slides">
                    {slidesContent}
                  </div>
                </div>
                {
                  settings.slides.length > 1 ?
                    <div class="slider-arrows">
                      <div class="slider-arrow prev">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.718 15.297">
                          <path d="M962.03,228.521l7.646,7.344-7.646,7.229" transform="translate(970.401 243.457) rotate(180)"></path>
                        </svg>
                      </div>
                      <div class="slider-arrow next">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.718 15.297">
                          <path d="M962.03,228.521l7.646,7.344-7.646,7.229" transform="translate(-961.684 -228.159)"></path>
                        </svg>
                      </div>
                    </div>
                  :
                    ''
                }
              </div>
            </div>
          )
          break;
        case 'drag_and_drop':
          var cards = settings.cards;
          console.log(settings);
          content = (
            <div className="element-container element-container-drag_and_drop" data-id={this.props.element.id}>
              <div className="drag-and-drop">
                <ElementBox
                  {...this.props.element}
                  element={this.props.element} urls={this.props.urls}
                />
                <div className="areas">
                  <div className="areas-column">
                    {
                      settings.fields.map((field, index) => {
                        var value = field.settings.field
                        if(index % 2 === 0) {
                          return(
                            <div
                              key={field.id}
                              className="area field"
                              data-index={index}
                            >
                              <div className="area-header">
                                {value}
                              </div>
                              <div className="area-body dropzone"></div>
                            </div>
                          )
                        } else {
                          return null
                        }
                      })
                    }
                  </div>
                  <div className="areas-column">
                    <div className="area cards dropzone">
                      {
                        settings.cards.map((card, index) => {
                          var value = card.settings.text
                          var field = parseInt(card.settings.field)
                          if(isNaN(field)) {
                            field = 0
                          }
                          return(
                            <div
                              key={card.id}
                              className="card"
                              style={{cursor: 'move'}}
                              data-option={field}
                            >
                              {value}
                            </div>
                          )
                        })
                      }
                      <div className="buttons">
                        <div className="button resolve">Zur Auflösung</div>
                      </div>
                    </div>
                  </div>
                  <div className="areas-column">
                    {
                      settings.fields.map((field, index) => {
                        var value = field.settings.field
                        if(index % 2 === 1) {
                          return(
                            <div
                              key={field.id}
                              className="area field"
                              data-index={index}
                            >
                              <div className="area-header">
                                {value}
                              </div>
                              <div className="area-body dropzone"></div>
                            </div>
                          )
                        } else {
                          return null
                        }
                      })
                    }
                  </div>
                </div>
  							<div class="solution">
                  <div class="headline">Deine Auswahl</div>
  								<div class="cards user-solution"></div>
  								<div class="headline">Musterlösung</div>
  								<div class="cards master-solution">
                    {
                      settings.cards.map((card, index) => {
                        var value = card.settings.text
                        var field = parseInt(card.settings.field)
                        if(isNaN(field)) {
                          field = 0
                        }
                        return(
                          <div
                            key={card.id}
                            className="card"
                          >
                            <div className="field">{settings.fields[field].settings.field}</div>
                            <div className="text">{value}</div>
                          </div>
                        )
                      })
                    }
  								</div>
  							</div>
              </div>
            </div>
          )
          break;
        case 'multiple_choice':
          var answers = settings.answers.map((answer, index) => {
            var solution
            if(answer.settings.field == 'Teilweise richtig') {
              solution = 'partly-correct'
            } else if(answer.settings.field == 'Falsch') {
              solution = 'wrong'
            } else {
              solution = 'correct'
            }
            return(
              <div className="button answer-item no-hover" style={{cursor: 'pointer'}}>
                <div className="text">{answer.settings.text}</div>
                <div class="solution">
                  {
                    solution !== 'wrong' ?
                      <div class="icon correct">
        								<svg xmlns="http://www.w3.org/2000/svg" width="9.445" height="11.452" viewBox="0 0 9.445 11.452">
        								  <path d="M6556.67,2596.9h3.907v-9.391" transform="translate(-4379.294 -5519.182) rotate(30)" stroke-width="2" />
        								</svg>
      		           </div>
                    :
                      ''
                  }
                  {
                    solution !== 'correct' ?
                      <div class="icon wrong">
      									<svg xmlns="http://www.w3.org/2000/svg" width="9.899" height="9.899" viewBox="0 0 9.899 9.899">
      									  <g transform="translate(-9800.05 -2089.05)">
      									    <path d="M9809.949,2097.535l-1.414,1.414-8.485-8.485,1.415-1.414Z" />
      									    <path d="M9809.949,2090.465l-8.484,8.485-1.415-1.414,8.485-8.485Z" />
      									  </g>
      									</svg>
      								</div>
                    :
                      ''
                  }
                </div>
              </div>
            )
          })
          var answersList = (
            <div className="answers-list">
              {answers}
            </div>
          )
          content = (
            <div className="element-container element-container-multiple_choice">
              <div className="multiple-choice">
                <ElementBox
                  {...this.props.element}
                  element={this.props.element} urls={this.props.urls}
                />
                {answersList}
                <div className="buttons">
                  <div className="button resolve">Zur Auflösung</div>
                </div>
              </div>
            </div>
          )
          break;
        case 'video':
          content = (
            <div className="element-container element-container-video">
              <div className="video">
                { /* <Video id={settings.video} /> */ }
              </div>
            </div>
          )
          break;
        case 'free_text_field':
          content = (
            <div className="element-container element-container-free_text_field">
              <div className="free-text-field">
                <ElementBox
                  {...this.props.element}
                  element={this.props.element} urls={this.props.urls}
                />
                <textarea className="input-field" placeholder={settings.placeholder} style={{border: 'none'}}>
                </textarea>
              </div>
            </div>
          )
          break;
        case 'slide_control':
          content = (
            <div className="element-container element-container-slide_control">
              <div className="slide-control">
                <ElementBox
                  {...this.props.element}
                  element={this.props.element} urls={this.props.urls}
                />
                {
                  settings.image !== '' ?
                    <div class="image">
                      <Image id={settings.image} element={this.props.element} urls={this.props.urls} />
                    </div>
                  :
                    ''
                }
                <div class="control">
                  <div class="point point-left">{settings.point_left}</div>
                  <div class="input">
                    <div class="rail">
                      <div class="handle"></div>
                    </div>
                  </div>
                  <div class="point point-right">{settings.point_right}</div>
                </div>
                <div class="buttons">
                  <div class="button resolve">Bestätigen</div>
                </div>
              </div>
            </div>
          )
          break;
        case 'pairs':
          var buttons = []
          var pairs = settings.pairs.map((pair, index) => {
            buttons.push({
              text: pair.settings.text_1,
              pair_index: index
            });
            buttons.push({
              text: pair.settings.text_2,
              pair_index: index
            });
          })
          buttons.sort(() => Math.random() - 0.5)
          var pairsList = buttons.map((button, index) => {
            return(
              <div className="button pair-item no-hover" data-pair-id={button.pair_index} style={{cursor: 'pointer'}}>
                {button.text}
              </div>
            )
          })
          var solutions = settings.pairs.map((pair, index) => {
            return(<div class="selected-pair">
              <div class="button pair-item no-hover">{pair.settings.text_1}</div>
              <div class="button pair-item no-hover">{pair.settings.text_2}</div>
            </div>)
          })
          content = (
            <div className="element-container element-container-pairs">
              <div className="pairs">
                <ElementBox
                  {...this.props.element}
                  element={this.props.element} urls={this.props.urls}
                />
                <div className="pairs-list">
                  <div className="pair-items">
                    {pairsList}
                  </div>
  								<div class="selected-pairs"></div>
                </div>
  							<div class="solution">
  								<div class="headline">
  									Deine Auswahl
  			           </div>
  								<div class="solution-pairs user-solution"></div>
  								<div class="headline">Musterlösung</div>
  								<div class="solution-pairs master-solution">
  									{solutions}
  								</div>
  							</div>
                <div className="buttons">
                  <div className="button resolve">Zur Auflösung</div>
                </div>
              </div>
            </div>
          )
          break;
        default:
          content = this.props.element.type
      }
    }
    return(
      <div className="element">
        {content}
      </div>
    )
  }
}

export default Element
