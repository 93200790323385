import React from 'react'

import CmsHelper from '../../../../../helpers/Cms'


class Audio extends React.Component {
  constructor(props) {
    super(props)

    this.url = this.props.urls[this.props.element.id + '_image'] !== undefined ? this.props.urls[this.props.element.id + '_image'] : '';
    if(this.props.url) {
      this.url = this.props.url;
    }
    this.type = 'image';
  }

  render() {
    return(
      this.url !== '' ?
        [
          this.type === 'video' ?
            <video key="1" className="image-video" src={this.url} loop autoPlay muted></video>
          :
            <img key="1" src={this.url} alt="" />
        ]
      :
        <div key="1" className="image-placeholder"></div>
    )
  }
}

export default Audio
